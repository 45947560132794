import {TAppInitialController} from 'core/redux/types';
import {actionSetBreadCrumbsList} from 'modules/breadcrumbs/slice';
import {getPopularCategoryList} from 'modules/categories/thunks';
import {getPopularCollectionList} from 'modules/collections/thunks';
import {getHomeServicesList} from 'modules/home-page/thunks';
import {getSelectionList} from 'modules/products/thunks';
import {getPopularPromoList} from 'modules/promo/thunks';
import {getShopList} from 'modules/shops/thunks';
// import {getPopularServiceList} from 'modules/services/thunks';
import {PAGES} from 'routing/constants';

export const homePageController: TAppInitialController<void> = async ({dispatch}) => {
    await Promise.all([
        dispatch(getPopularCategoryList),
        dispatch(actionSetBreadCrumbsList([])),
        dispatch(getPopularCollectionList),
        dispatch(getSelectionList('bestseller')),
        dispatch(getSelectionList('new')),
        dispatch(getSelectionList('popular')),
        dispatch(getPopularPromoList),
        dispatch(getShopList),
        // dispatch(getPopularServiceList),
    ]);
};

export const servicePageController: TAppInitialController<void> = async ({dispatch}) => {
    await Promise.all([
        dispatch(getHomeServicesList),
        dispatch(actionSetBreadCrumbsList([PAGES.HOME_PAGE, PAGES.SERVICE_PAGE])),
    ]);
};
