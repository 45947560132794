import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectPopularBrandList} from 'modules/brand/selectors';
import {selectPopularCategoryList} from 'modules/categories/selectors';
import {selectPopularCollectionList} from 'modules/collections/selectors';
import {selectBannerListByPlace} from 'modules/common/selectors';
import {selectContentPage} from 'modules/content/selectors';
import {HomePageContext} from 'modules/home-page/context';
import {HomePage} from 'modules/home-page/pages/HomePage';
import {selectAdvicesList, selectPrivateLabelsList, selectServicesList} from 'modules/home-page/selectors';
import {selectPopularPromoList} from 'modules/promo/selectors';
import {selectPopularServices} from 'modules/services/selectors';
import {PromoPlace} from 'new-models';
import Head from 'next/head';
import React, {FC, useMemo} from 'react';

export const HomePageProvider: FC = () => {
    const homePrivateLabelList = useAppSelector(selectPrivateLabelsList);
    const homeAdviceList = useAppSelector(selectAdvicesList);
    const homeServiceList = useAppSelector(selectServicesList);
    const homeBannerSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.Header));
    const homeBannerSecondSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.HeaderSecond));
    const giftCardSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.GiftCard));
    const floristryStudioSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.FloristryStudio));
    const floristryStudioLeftSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.FloristryStudioLeft));
    const floristryStudioRightSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.FloristryStudioRight));
    const summerFiestaSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.SummerFiesta));
    const summerFiestaLeftSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.SummerFiestaLeft));
    const summerFiestaRightSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.SummerFiestaRight));
    const aboutSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.About));
    const aboutLeftSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.AboutLeft));
    const aboutRightSlideList = useAppSelector(selectBannerListByPlace(PromoPlace.AboutRight));
    const popularCategoryList = useAppSelector(selectPopularCategoryList);
    const popularBrandList = useAppSelector(selectPopularBrandList);
    const popularPromoList = useAppSelector(selectPopularPromoList);
    const popularCollectionList = useAppSelector(selectPopularCollectionList);
    const popularServices = useAppSelector(selectPopularServices);
    const contentPage = useAppSelector(selectContentPage);
    const {title, description, keywords} = contentPage?.seoMetaData ?? {
        description: '',
        keywords: '',
        title: '',
    };

    const homePageContextValue = useMemo(() => {
        return {
            aboutLeftSlideList,
            aboutRightSlideList,
            aboutSlideList,
            floristryStudioLeftSlideList,
            floristryStudioRightSlideList,
            floristryStudioSlideList,
            giftCardSlideList,
            homeAdviceList,
            homeBannerSecondSlideList,
            homeBannerSlideList,
            homePrivateLabelList,
            homeServiceList,
            popularBrandList,
            popularCategoryList,
            popularCollectionList,
            popularPromoList,
            popularServices,
            summerFiestaLeftSlideList,
            summerFiestaRightSlideList,
            summerFiestaSlideList,
        };
    }, [
        aboutLeftSlideList,
        aboutRightSlideList,
        aboutSlideList,
        floristryStudioLeftSlideList,
        floristryStudioRightSlideList,
        floristryStudioSlideList,
        giftCardSlideList,
        homeAdviceList,
        homeBannerSlideList,
        homePrivateLabelList,
        homeServiceList,
        popularBrandList,
        popularCategoryList,
        popularCollectionList,
        popularPromoList,
        popularServices,
        summerFiestaLeftSlideList,
        summerFiestaRightSlideList,
        summerFiestaSlideList,
        homeBannerSecondSlideList,
    ]);

    return (
        <>
            <Head>
                {title && <title>{title}</title>}
                {description && (
                    <>
                        <meta content={description} name="description" />
                        <meta content={description} property="og:description" />
                    </>
                )}
                {keywords && <meta content={keywords} property="keywords" />}
            </Head>
            <HomePageContext.Provider value={homePageContextValue}>
                <HomePage />
            </HomePageContext.Provider>
        </>
    );
};
