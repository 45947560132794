import {withBasicData} from 'core/next/controllers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {homePageController} from 'modules/home-page/controller';
import {HomePageProvider} from 'modules/home-page/providers/HomePageProvider';
import {getProductGroupsList} from 'modules/products/thunks';
import {ProductGroupEnum} from 'new-models';
import type {NextPage} from 'next';
import Head from 'next/head';
import {useEffect} from 'react';

const IndexPage: NextPage = () => {
    const dispatch = useAppDispatch();
    useBottomBar(BOTTOM_BAR_BUTTONS.main);

    useEffect(() => {
        dispatch(getProductGroupsList(ProductGroupEnum.Viewed));
    }, [dispatch]);

    return (
        <>
            <Head>
                <meta
                    content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover, width=device-width"
                    name="viewport"
                />
                <meta content="yes" name="apple-mobile-web-app-capable" />
                <meta content="yes" name="mobile-web-app-capable" />
                <meta content="yes" name="apple-touch-fullscreen" />
                <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
                <title>Магазин</title>
            </Head>
            <HomePageProvider />
        </>
    );
};

IndexPage.getInitialProps = withBasicData(async (store, ctx) => {
    await homePageController(store, ctx);

    return {
        props: {},
    };
});

export default IndexPage;
